<template>
  <div>
    <wlTreeSelect
      leaf
      width="240"
      checkbox
      :data="treeData"
      @change="hindleChanged"
      v-model="selected"
      :showTotal="2"
    ></wlTreeSelect>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // value: [], // 选中值
      props: {
        label: "name",
        value: "id",
      }, // 配置
      treeData: [],
      selected: [], // 树下拉框选中数据
    };
  },
  mounted() {
    this.UserInfo = JSON.parse(window.localStorage.getItem("user"));
    this.getdeplist();

this.selected = [{id:"197",
is_valid: false,
name: "行政部",
parent_id: "189"},{id: "196",
is_valid: false,
name: "仓储部",
parent_id: "189"}]
  },
  methods: {
    getdeplist() {
      let data = {
        method: "co.dept.list",
        agent_id: this.UserInfo.agent_id,
        manager_id: this.UserInfo.manager_id,
      };
      this.$serve(data).then((res) => {
        var result = [];
        if (res.data.data != null) result.push(this.getFirstObj(res.data.data));
        this.treeData = result
      });
    },
    getFirstObj(obj) {
      for (var key in obj) {
        return obj[key];
      }
    },

    hindleChanged(val) {
      console.log(`2`,val);
      console.log(this.selected);
    },
  },
};
</script>